import { AfterViewInit, Component } from '@angular/core';
import { FilterDayEnum } from 'src/app/global-constants';
import { RAGApiService } from 'src/app/services/rag-api/rag-api.service';
import { filterBatchInformation } from '../processedfiles/filterable-batch';
import {
  BatchDto,
  BatchRecordUpdateType,
  Status,
} from '@wex-risk/rp-portfolio-utils';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
// Home page component
export class HomeComponent implements AfterViewInit {
  protected cliBatchFiles: BatchDto[] = [];
  protected cldBatchFiles: BatchDto[] = [];
  protected cliSuccesses = 0;
  protected cliFailures = 0;
  protected cldSuccesses = 0;
  protected cldFailures = 0;

  constructor(private ragService: RAGApiService) {}

  // retrieves files after view is initialized
  ngAfterViewInit() {
    this.getFiles().catch((err) => console.error(err));
  }

  //retrieves files
  async getFiles() {
    const files = await this.ragService.getFiles();

    this.cldBatchFiles = filterBatchInformation(files.files, {
      updateTypes: BatchRecordUpdateType.CLD,
      submittedOn: FilterDayEnum.Days30,
      status: [Status.ACCEPTED, Status.FAILED],
    });

    this.cliBatchFiles = filterBatchInformation(files.files, {
      updateTypes: BatchRecordUpdateType.CLI,
      submittedOn: FilterDayEnum.Days30,
      status: [Status.ACCEPTED, Status.FAILED],
    });

    this.cliFailures = this.cliBatchFiles.filter(
      (r) => r.failedRecords > 0,
    ).length;
    this.cliSuccesses = this.cliBatchFiles.filter(
      (r) => r.successRecords === r.totalRecords,
    ).length;
    this.cldFailures = this.cldBatchFiles.filter(
      (r) => r.failedRecords > 0,
    ).length;
    this.cldSuccesses = this.cldBatchFiles.filter(
      (r) => r.successRecords === r.totalRecords,
    ).length;
  }
}
