import { Component, OnInit } from '@angular/core';
import { OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { filter } from 'rxjs';
import { UserInfoService } from './services/userinfo/userinfo.service';
import { plainToInstance } from 'class-transformer';
import { AccessRightsDto, OktaJwtDto } from '@wex-risk/rp-portfolio-utils';
import { EnvironmentService } from './services/environment.service';
import { faPlusMinus } from '@fortawesome/free-solid-svg-icons';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from 'src/environments/environment';
import packageInfo from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
// main app component
export class AppComponent implements OnInit {
  protected readonly icon = faPlusMinus;
  protected readonly title = 'WEX Risk Action Gateway';
  protected readonly contentMargin = 262;

  protected readonly links = [
    {
      url: '/home',
      icon: 'home',
      name: 'Home',
    },
    {
      url: '/submit',
      icon: 'add_circle_outline',
      name: 'Bulk Account Action',
    },
    {
      url: '/exclusions',
      icon: 'block',
      name: 'Account Exclusions',
    },
    {
      url: '/r360',
      icon: 'restart_alt',
      name: 'R360',
    },
    {
      url: '/processedfiles',
      icon: 'cloud_upload',
      name: 'Submitted Files',
    },
    {
      url: '/manualreview',
      icon: 'credit_card',
      name: 'Credit line requests',
    },
    {
      url: '/settings',
      icon: 'settings',
      name: 'Admin Settings',
    },
  ];

  protected user: OktaJwtDto | undefined;
  protected isExpanded = true;
  protected isLoading = true;

  constructor(
    private readonly oktaStateService: OktaAuthStateService,
    private readonly oktaAuth: OktaAuth,
    private readonly userInfoService: UserInfoService,
    private readonly environmentService: EnvironmentService,
  ) {
    oktaAuth.tokenManager.on('expired', () => {
      console.log('expired');
      this.signOut().catch(console.error);
    });

    datadogRum.init({
      applicationId: 'db70bef1-c0c0-4a7e-a083-f8341e03e266',
      clientToken: 'pub92df776d21ddc5cc9381ec210a0c36c0',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.com',
      service: 'risk-action-gateway',
      env: environment.environmentName,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: packageInfo.version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }

  //retrieves user's information and passes it to userInfoService
  public ngOnInit() {
    this.oktaStateService.authState$
      .pipe(filter((authState) => authState?.isAuthenticated))
      .subscribe(() => {
        this.oktaAuth
          .getUser()
          .then((claims) => {
            this.user = plainToInstance(OktaJwtDto, claims);
            this.userInfoService.setUser(this.user);
            this.isLoading = false;
          })
          .catch(console.error);
      });
  }
  /**
   * This method checks if the current user has access to a given path.
   *
   * @param {string} path - The path to check access for.
   * @returns {boolean} - Returns true if the user has access, false otherwise.
   */
  public hasAccess(path: string): boolean {
    const accessRights: AccessRightsDto[] = [];
    switch (path) {
      case '/submit':
        accessRights.push(AccessRightsDto.UPDATE_ACCOUNT_CREDITLINE);
        break;
      case '/processedfiles':
        accessRights.push(
          AccessRightsDto.ACCESS_ONLY_MY_SUBMITTED_FILES,
          AccessRightsDto.ACCESS_OTHERS_SUBMITTED_FILES,
        );
        break; // this is done more granularly
      case '/home':
        return true; // everyone can see the home page
      case '/exclusions':
        accessRights.push(
          AccessRightsDto.ACCESS_NAF_ACCOUNTS,
          AccessRightsDto.ACCESS_OTR_ACCOUNTS,
          AccessRightsDto.UPDATE_ACCOUNT_CREDITLINE,
          AccessRightsDto.UPDATE_ACCOUNT_STATUS_SUSPEND,
          AccessRightsDto.ACCESS_OTHERS_SUBMITTED_FILES,
        );
        break;
      case '/manualreview':
        accessRights.push(AccessRightsDto.UPDATE_ACCOUNT_MANUAL_REVIEW);
        break;
      case '/r360':
        accessRights.push(AccessRightsDto.ACCESS_R360_VIEW_ONLY);
        break;
      case '/settings':
        accessRights.push(AccessRightsDto.ACCESS_ADMIN_SETTINGS);
        break;
      default:
        console.warn(`No access rights defined for path: ${path}`);
        return false;
    }

    if (accessRights.length === 0) {
      return true;
    }
    const x = this.user?.hasAnyAccessRights(...accessRights);
    return x || this.environmentService.ignoreScopes;
  }

  //signs out the user
  public async signOut(): Promise<void> {
    this.oktaAuth.clearStorage();
    await this.oktaAuth.signOut();
  }
}
