import { EnvironmentType } from './environment-type';

export const environment = <EnvironmentType>{
  production: false,
  environmentName: 'Stage',
  baseApiUrl: 'https://riskplatform-stage.wexapi.com/api/platform/rag/',
  baseR360ApiUrl: 'https://riskplatform-stage.wexapi.com/api/platform/r360/',
  infoModalDate: new Date('2024-07-31'),
  ignoreScopes: false,
  // remove after QA is done
  // oktaIssuer: 'https://wexinc.oktapreview.com/oauth2/aus1h7jvzriP5gqJI0h8',
  // oktaClientId: '0oa1h7jpl38MooRyX0h8',
  // oktaRedirectUri: `${window.location.origin}/login/callback`,
  // oktaLogoutUrl: 'https://wexinc.oktapreview.com',
  // oktaPkce: true,
  oktaIssuer: 'https://riskplatform-dev-wex.oktapreview.com/oauth2/default',
  oktaClientId: '0oa7itrsioIvChcfo1d7',
  oktaRedirectUri: `${window.location.origin}/login/callback`,
  oktaLogoutUrl: 'https://riskplatform-dev-wex.oktapreview.com',
  oktaPkce: true,
};
