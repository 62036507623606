import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { OktaAuth } from '@okta/okta-auth-js';
import { EnvironmentService } from './services/environment.service';

@Injectable()
/**
 * Intercepts HTTP requests to add an Authorization header to requests to the API
 */
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private oktaAuth: OktaAuth,
    private readonly envService: EnvironmentService,
  ) {}

  /**
   * Implments the intercept method of HttpInterceptor
   * @param request
   * @param next
   * @returns
   */
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(this.addAuthHeaderToAllowedOrigins(request));
  }

  //include auth header for allowed origins
  private addAuthHeaderToAllowedOrigins(
    request: HttpRequest<unknown>,
  ): HttpRequest<unknown> {
    let req = request;

    const allowedOrigins = [
      new URL(this.envService.apiPath()).origin,
      new URL(this.envService.apiPath('', true)).origin,
    ];

    // console.log('allowedOrigins', allowedOrigins);
    // console.log('request.url', new URL(request.url).origin);

    if (allowedOrigins.includes(new URL(request.url).origin)) {
      const authToken = this.oktaAuth.getAccessToken();
      req = request.clone({
        setHeaders: { Authorization: `Bearer ${authToken}` },
      });
    }

    return req;
  }
}
