import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { EnvironmentService } from '../environment.service';
import {
  BatchDto,
  BatchRecordDto,
  BatchRecordSubmissionDto,
  BatchRecordSubmissionResponseDto,
  ExclusionRecordPutDto,
  ExclusionsRecordSubmissionResponseDto,
  FilesResponseDto,
  PaginatedResult,
  TreatmentsSearchParametersDto,
  BatchRecordSubmissionRow,
  AdminSettingsDto,
  BatchRecordStatusDto,
} from '@wex-risk/rp-portfolio-utils';
import { ValidationErrorDto } from './dto/validation-error.dto';
import { plainToInstance } from 'class-transformer';
import { adminSettingsMock } from 'src/mock/admin-settings.mock';

@Injectable({ providedIn: 'root' })
//Service for interacting with the RAG API
export class RAGApiService {
  constructor(
    private http: HttpClient,
    private readonly envService: EnvironmentService,
  ) {}

  //retrieves all batches from RAG API
  async getFiles() {
    return plainToInstance(
      FilesResponseDto,
      await firstValueFrom(
        this.http.get<object>(this.envService.apiPath('treatments/batches')),
      ),
    );
  }

  //retrieves a single batch from RAG API
  async getFile(id: number) {
    return await firstValueFrom(
      this.http.get<BatchDto>(
        this.envService.apiPath(`treatments/batches/${id}`),
      ),
    );
  }

  //retrieves a record by it's guid
  getRecord(guid: string): Promise<BatchRecordDto> {
    return firstValueFrom(
      this.http.get<BatchRecordDto>(
        this.envService.apiPath(`treatments/records/${guid}`),
      ),
    );
  }

  //retrieve download link for a batch
  getDownloadLink(
    batchId: string,
    status?: BatchRecordStatusDto,
  ): Promise<{ downloadUrl: string }> {
    const statusParam = status ? `?status=${status}` : '';
    const url = firstValueFrom(
      this.http.get<{ downloadUrl: string }>(
        this.envService.apiPath(
          `treatments/batches/${batchId}/downloadurl${statusParam}`,
        ),
      ),
    );
    return url;
  }

  //retrieves all records for a batch
  getRecords(batchId: string): Promise<BatchRecordDto[]> {
    return firstValueFrom(
      this.http.get<BatchRecordDto[]>(
        this.envService.apiPath(`treatments/batches/${batchId}/records`),
      ),
    );
  }

  //submits contents from a file that'll be uploaded for validation
  async validateSubmission(submission: BatchRecordSubmissionDto) {
    try {
      return await firstValueFrom(
        this.http.post<{ status: 'success' }>(
          this.envService.apiPath('treatments/batches/submit/validate'),
          submission,
        ),
      );
    } catch (e) {
      console.error(e);
    }
    return undefined;
  }

  //validates a single row from a file that'll be uploaded
  async validateSubmissionRow(
    submission: BatchRecordSubmissionRow,
  ): Promise<
    | { status: 'success' }
    | { status: 'failure'; errors?: ValidationErrorDto[]; message?: string }
  > {
    try {
      return await firstValueFrom(
        this.http.post<{ status: 'success' }>(
          this.envService.apiPath('treatments/batches/submit/validate/row'),
          submission,
        ),
      );
    } catch (e) {
      console.error(e);
      if (e instanceof HttpErrorResponse) {
        if (e.status === 0) {
          return {
            status: 'failure',
            message: 'Network Error',
          };
        } else if (e.status === (HttpStatusCode.BadRequest as number)) {
          // error.message should be ValidationErrorDto[]
          const errors = (e.error as { message: ValidationErrorDto[] }).message;

          return {
            status: 'failure',
            errors,
          };
        } else {
          return {
            status: 'failure',
            message: e.message,
          };
        }
      } else {
        throw e;
      }
    }
  }

  // searches for records in RAG API
  async searchRecords(
    searchParams: TreatmentsSearchParametersDto,
  ): Promise<PaginatedResult<BatchRecordDto>> {
    const queryString = new URLSearchParams(
      Object.entries(searchParams),
    ).toString();
    const batchRecords = await lastValueFrom(
      this.http.get<PaginatedResult<BatchRecordDto>>(
        this.envService.apiPath(`treatments/records?${queryString}`),
      ),
    );
    return batchRecords;
  }

  //uploads a file containing accounts to be excluded from treatments to the RAG API
  async submitExclusions(data: ExclusionRecordPutDto) {
    return await lastValueFrom(
      this.http.put<ExclusionsRecordSubmissionResponseDto>(
        this.envService.apiPath('exclusions'),
        data,
      ),
    );
  }

  //submits a file of treatments to the RAG API
  async submitTreatments(data: BatchRecordSubmissionDto) {
    return await lastValueFrom(
      this.http.post<BatchRecordSubmissionResponseDto>(
        this.envService.apiPath('treatments/batches/submit'),
        data,
      ),
    );
  }

  /**
   * Submits manual review decision to RAG API
   * @param recordId
   * @param decision
   */
  async reviewRecord(recordId: string, decision: string) {
    return await lastValueFrom(
      this.http.patch(
        this.envService.apiPath(`treatments/records/${recordId}/review`),
        { decision },
      ),
    );
  }
  //retrieves adming settings from rag-service
  async getAdminSettings(): Promise<AdminSettingsDto[]> {
    // skipqc: JS-0105
    return await Promise.resolve(adminSettingsMock);
  }
}
