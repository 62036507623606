<div class="title">Dashboard <span>Last 30 days</span></div>
<div class="home-cards">
  <!-- TODO: Deduplicate cards with @for directive -->
  <mat-card>
    <mat-card-header>
      <mat-card-title>Credit Limit Increase</mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="cliBatchFiles">
      <div class="total-records">
        <span class="record-count">{{ cliBatchFiles.length }}</span>
        <div>total files processed</div>
      </div>
      <div class="sub-total-records">
        <div>
          <span class="record-count success">{{ cliSuccesses }}</span>
          <div>files with all successful records</div>
        </div>
        <div>
          <span class="record-count failure">{{ cliFailures }}</span>
          <div>files with failed records</div>
        </div>
      </div>
      <div class="goto-card-link">
        <button color="primary" mat-raised-button [routerLink]="['/submit']">
          <mat-icon mat-list-icon>add_circle_outline</mat-icon>Credit limit
          increase
        </button>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Credit Limit Decrease</mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="cldBatchFiles">
      <div class="total-records">
        <div class="record-count">{{ cldBatchFiles.length }}</div>
        <div>total files processed</div>
      </div>
      <div class="sub-total-records">
        <div>
          <span class="record-count success">{{ cldSuccesses }}</span>
          <div>files with all successful records</div>
        </div>
        <div>
          <span class="record-count failure">{{ cldFailures }}</span>
          <div>files with failed records</div>
        </div>
      </div>
      <div class="goto-card-link">
        <button color="primary" mat-raised-button [routerLink]="['/submit']">
          <mat-icon mat-list-icon fontSet="material-icons-outlined"
            >do_not_disturb_on</mat-icon
          >Credit limit decrease
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
