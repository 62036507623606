import {
  AdminSettingType,
  AdminSettingsDto,
} from '@wex-risk/rp-portfolio-utils';

export const adminSettingsMock: AdminSettingsDto[] = [
  {
    type: AdminSettingType.DISABLE_PROCESSING_FILES,
    enabled: false,
    modifiedBy: 'admin',
    updatedAt: new Date(),
  },
];
