import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { AccessRightsDto } from '@wex-risk/rp-portfolio-utils';
import { roleAuthorizationGuard } from './role-authorization.guard';

const appRoutes: Routes = [
  {
    path: 'processedfiles',
    loadChildren: () =>
      import('./pages/processedfiles/processedfiles.module').then(
        (m) => m.ProcessedFilesModule,
      ),
    canActivate: [OktaAuthGuard, roleAuthorizationGuard],
    data: {
      requiredAccessRights: [
        AccessRightsDto.ACCESS_ONLY_MY_SUBMITTED_FILES,
        AccessRightsDto.ACCESS_OTHERS_SUBMITTED_FILES,
      ],
    },
  },
  {
    path: 'submit',
    loadChildren: () =>
      import('./pages/submit/submit.module').then((m) => m.SubmitModule),
    canActivate: [OktaAuthGuard, roleAuthorizationGuard],
    data: {
      requiredAccessRights: [AccessRightsDto.UPDATE_ACCOUNT_CREDITLINE],
    },
  },
  {
    path: 'exclusions',
    loadChildren: () =>
      import('./pages/exclusion/exclusion.module').then(
        (m) => m.ExclusionModule,
      ),
    canActivate: [OktaAuthGuard, roleAuthorizationGuard],
    data: {
      requiredAccessRights: [
        AccessRightsDto.ACCESS_NAF_ACCOUNTS,
        AccessRightsDto.ACCESS_OTR_ACCOUNTS,
        AccessRightsDto.UPDATE_ACCOUNT_CREDITLINE,
        AccessRightsDto.UPDATE_ACCOUNT_STATUS_SUSPEND,
        AccessRightsDto.ACCESS_OTHERS_SUBMITTED_FILES,
      ],
    },
  },
  {
    path: 'manualreview',
    loadChildren: () =>
      import('./pages/manualreview/manualreview.module').then(
        (m) => m.ManualreviewModule,
      ),
    canActivate: [OktaAuthGuard, roleAuthorizationGuard],
    data: {
      requiredAccessRights: [AccessRightsDto.UPDATE_ACCOUNT_MANUAL_REVIEW],
    },
  },
  {
    path: 'r360',
    loadChildren: () =>
      import('./pages/r360/r360.module').then((m) => m.R360Module),
    canActivate: [OktaAuthGuard, roleAuthorizationGuard],
    data: {
      requiredAccessRights: [AccessRightsDto.ACCESS_R360_VIEW_ONLY],
    },
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [OktaAuthGuard, roleAuthorizationGuard],
    data: {
      requiredAccessRights: [AccessRightsDto.ACCESS_ADMIN_SETTINGS],
    },
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomeModule),
    canActivate: [OktaAuthGuard],
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },

  { path: 'login/callback', component: OktaCallbackComponent },
  // { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
// skipcq: JS-D1001, JS-0327
export class AppRoutingModule {}
